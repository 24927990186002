import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { HiThumbUp, HiThumbDown } from "react-icons/hi";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
    const [numClick, setNumClick] = useState(0);

    const handleClick = async (increase, amount) => {
        const response = await fetch(process.env.REACT_APP_API_URL, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                increase: increase,
                amount: amount,
            }),
        });
        if (!response.ok) {
            alert(`network error, code ${response.status}, message ${response.statusText}`);
        }

        const data = await response.json();
        setNumClick(data.count || 0);
    };

    useEffect(() => {
        document.title = "Henry's Ego Boost";
        (async () => {
            const response = await fetch(process.env.REACT_APP_API_URL);
            if (!response.ok) {
                alert(`network error, code ${response.status}, message ${response.statusText}`);
            }

            const data = await response.json();
            setNumClick(data.count || 0);
        })();
    }, []);

    return (
        <div
            style={{ backgroundColor: "#313a46" }}
            className="d-flex flex-column min-vh-100 justify-content-center align-items-center"
        >
            <div>
                <h1 style={{ color: "#fff", fontSize: "10vw" }}>I like him {numClick} time(s)</h1>
                <div className="d-flex justify-content-center">
                    <Button
                        style={{ fontSize: "5vw", width: "45%" }}
                        className=""
                        variant="success"
                        onClick={() => handleClick(true, 5)}
                    >
                        <HiThumbUp /> +5
                    </Button>
                    <span style={{ width: "2%" }}></span>
                    <Button
                        style={{ fontSize: "5vw", width: "45%" }}
                        className=""
                        variant="success"
                        onClick={() => handleClick(true, 1)}
                    >
                        <HiThumbUp />
                    </Button>
                    <span style={{ width: "10%" }}></span>
                    <Button
                        style={{ fontSize: "5vw", width: "45%" }}
                        className=""
                        variant="danger"
                        onClick={() => handleClick(false, 1)}
                    >
                        <HiThumbDown />
                    </Button>
                    <span style={{ width: "2%" }}></span>
                    <Button
                        style={{ fontSize: "5vw", width: "45%" }}
                        className=""
                        variant="danger"
                        onClick={() => handleClick(false, 5)}
                    >
                        <HiThumbDown /> -5
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default App;
